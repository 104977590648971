import React, { useState, Fragment } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { ClockIcon } from '@heroicons/react/24/outline';
import { cancelTransaction, getTransactionStatus } from '../utils/api';
import { formatAmount } from '../utils/format';

const OutstandingPayment = ({ sessionId, transaction }) => {
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(true);
    const [modalMessage, setModalMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const transactionDate = new Date(transaction.CreatedAt);
    const isTransactionOlderThan24Hours = new Date() - transactionDate > 24 * 60 * 60 * 1000;
    const [isPending, setIsPending] = useState(false);
    const [cancelError, setCancelError] = useState('');

    const handleCheckStatus = async () => {
        setLoading(true);
        try {
            const response = await getTransactionStatus(transaction.ID);
            const { status, returnUrl } = response.data;
            setLoading(false);

            if (status === 1) {
                confetti();
                if (returnUrl) {
                    setReturnUrl(returnUrl);
                    const interval = setInterval(() => {
                        setCountdown(prev => {
                            if (prev === 0) {
                                clearInterval(interval);
                                window.location.href = returnUrl;
                            }
                            return prev - 1;
                        });
                    }, 1000);
                }
            } else if (status === 0) {
                setIsPending(true);
            } else if (status === 2) {
                window.location.href = `/v1/payments/${sessionId}`;
            }
        } catch (error) {
            console.error('Failed to get transaction status', error);
            setLoading(false);
        }
    };

    const handleCancel = async () => {
        setLoading(true);
        try {
            const response = await getTransactionStatus(transaction.ID);
            const { status } = response.data;

            if (status !== 0) {
                // Redirect for any status other than 0
                window.location.href = `/v1/payments/${sessionId}`;
            } else {
                // Attempt to cancel the transaction if status is 0
                const cancelResponse = await cancelTransaction(transaction.ID);
                if (cancelResponse.data.error) {
                    setCancelError(cancelResponse.data.error);
                } else {
                    setIsSuccess(true);
                    window.location.href = `/v1/payments/${sessionId}`;
                }
            }
        } catch (error) {
            console.error(`Failed to cancel transaction: ${error.message}`);
            if (error.response && error.response.data && error.response.data.error) {
                setCancelError(error.response.data.error);
            } else {
                setModalMessage(`Failed to cancel transaction: ${error.message}`);
                setIsSuccess(false);
            }
        } finally {
            setLoading(false);
            setModalOpen(true);
        }
    };

    return (
        <Transition show={modalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => { /* no-op */ }}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-orange-100">
                                        <ClockIcon className="h-6 w-6 text-orange-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            {modalMessage || 'Outstanding Payment'}
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Your recent payment has been processed, but the outcome is pending. Depending on your chosen payment method, this process may take 1-5 business days to complete. We will notify you via email once the payment has been successfully booked. Please note that further payments can only be made after all outstanding transactions have been finalized.
                                            </p>
                                        </div>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">Transaction ID: {transaction.ID}</p>
                                            <p className="text-sm text-gray-500">Amount: {formatAmount(transaction.Amount)} {transaction.Currency ? transaction.Currency.toUpperCase() : ''}</p>
                                            <p className="text-sm text-gray-500">Created on: {transactionDate.toLocaleDateString()}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    {isTransactionOlderThan24Hours && !isPending && (
                                        <button
                                            onClick={handleCheckStatus}
                                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            disabled={loading}
                                        >
                                            {loading ? 'Checking...' : 'Check Status of Payment'}
                                        </button>
                                    )}
                                    {!isTransactionOlderThan24Hours && !cancelError && !isSuccess && (
                                        <button
                                            onClick={handleCancel}
                                            className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                            disabled={loading}
                                        >
                                            {loading ? 'Cancelling...' : 'Cancel outstanding payment'}
                                        </button>
                                    )}
                                    {cancelError && (
                                        <p className="text-sm text-red-600">
                                            {cancelError}
                                        </p>
                                    )}
                                    {isPending && !isSuccess && (
                                        <p className="text-sm text-red-600">
                                            The transaction is already submitted to the bank and cannot be cancelled anymore. If the payment is credited successfully, your order will be approved automatically, typically within 3 business days.
                                        </p>
                                    )}
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition >
    );
};

export default OutstandingPayment;
